<template>
  <div class="sells q-px-md">

    <!-- CARDS -->
    <div class="cards q-mt-md">
      <div class="pedidos responsive full-width" style="height: 100%;">
        <div class="box bg-verde">
          <div class="titleCardsSells bLTR text-h6 text-white">
            Resumo dos Pedidos
          </div>
          <div class="row bg-verde bA" style="height: 100%;" v-for="resumo in resumoPedidos" :key="resumo">

            <div class="faltaPedir col-3">
              
              <q-card square class="faltaPedir">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Faltam
                      <br v-show="isMobile" />
                    Pedir
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                    {{ isNaN(resumo.apedir) ? 0 : resumo.apedir }}
                    <br v-if="isMobile"/>
                    unidades
                  </div>
                </div>
              </q-card>

            </div>

            <div class="aCaminho col-3">
              
              <q-card square class="aCaminho">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Produtos a
                      <br v-show="isMobile" />
                    Caminho
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                    {{ isNaN(resumo.acaminho) ? 0 : resumo.acaminho }}
                    <br v-if="isMobile"/>
                    unidades
                  </div>
                </div>
              </q-card>

            </div>

            <div class="entregues col-3">

              <q-card square class="entregues">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Produtos
                      <br v-show="isMobile" />
                    Entregues
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                    {{ isNaN(resumo.entregues) ? 0 : resumo.entregues }}
                    <br v-if="isMobile"/>
                    unidades
                  </div>
                </div>
              </q-card>

            </div>

            <div class="vlrTTL col-3">

              <q-card square class="vlrTTL">
                <div class="titleCards row bB justify-center bg-verdeclaro">
                  <div class="text">
                    Valor Total
                      <br v-show="isMobile" />
                    Pedidos
                  </div>
                </div>
                <div class="body column q-py-lg">
                  <div class="valor text-verde text-bold">
                      R$ 
                    <br v-if="isMobile"/>
                      {{ isNaN(resumo.vlrttl) ? 0 : parseFloat(resumo.vlrttl).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </div>
                </div>
              </q-card>

            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- FAIXA DE CONTROLE -->
    <div class="btns q-mt-md row justify-center">
      <q-btn no-caps icon="fas fa-notes-medical" label="Novo Pedido" @click="onAdd" style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white" />

      <q-btn icon="event" label="Selecionar Período" no-caps style="width: 280px; background-color: var(--primary)" class="q-mb-md q-mx-md text-white">
        <q-popup-proxy transition-show="scale" transition-hide="scale" >
          <q-date v-model="datePicker" range :locale="ptBR">
            <q-btn color="negative" label="Cancelar" flat v-close-popup />
            <q-btn color="primary" label="Confirmar" flat v-close-popup @click="onDate" />
          </q-date>
        </q-popup-proxy>
      </q-btn>
    </div>

    <!-- TABELA DE ACOMPANHAMENTO -->
    <div class="table">
      <q-table
        dense
        class="myTable"
        :rows="rows"
        :columns="cols"
        row-key="name"
        :rows-per-page-options="[10, 20, 50, 100, 99999]"
        rows-per-page-label="Linhas por página: "
        :filter="filter"
        no-results-label="Sem dados a serem mostrados"
        no-data-label="Sem dados a serem mostrados"
        :visible-columns="visibleCols"
        @row-click="onEditRow"
      >
        <template v-slot:top-left>
          <q-input filled dense dark v-model="filter" placeholder="Pesquisar" style="width: 150px">
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template v-slot:top-right>
          <q-select
            v-model="visibleCols"
            multiple
            filled
            color="yellow"
            dense
            options-dense
            display-value="Colunas"
            emit-value
            map-options
            dark
            :options="cols"
            option-value="name"
            options-cover
            style="width: 100px;"
          />
          <q-btn
            flat
            rounded
            style="background-color: var(--verdeclaro); color: white"
            icon="file_download"
            @click="exportTable(rows)"
          />
        </template>
      </q-table>
    </div>

    <!-- MODAL PARA INSERÇÃO DE NOVA VENDA -->
    <q-dialog v-model="modalOrders" full-width persistent>
      <div class="bg-white q-pa-lg q-gutter-y-sm">
        <div v-if="editRow" class="title text-h5 text-bold text-center text-verde q-mb-md">
          EDITANDO PEDIDO
        </div>
        <div v-else class="title text-h5 text-bold text-center text-verde q-mb-md">
          INCLUSÃO DE NOVO PEDIDO
        </div>

        <q-separator spaced size="5px" style="background-color: var(--primary)" />
        
        <q-scroll-area style="width: 100%; height: 60vh;" v-show="rebuild">
          <q-input class="oneFields q-mt-md" outlined v-model="supplier" type="text" label="Fornecedor" />
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="qtd" type="number" label="Quantidade" suffix="unidades" placeholder="0" @change="onCost" />
            <q-input class="threeFields" outlined v-model="costttl" type="number" label="Custo Total" prefix="R$" placeholder="0" @change="onCost" />
            <q-input class="threeFields" disable outlined v-model="unitcost" type="text" label="Custo Médio" prefix="R$" />
          </div>
          <div class="respRowForm q-gutter-y-sm q-mt-sm">
            <q-input class="threeFields" outlined v-model="dtOrder" type="date" stack-label label="Data Pedido" />
            <q-input class="threeFields" outlined v-model="tracking" type="text" label="Código Rastreio" />
            <q-input class="threeFields" outlined v-model="dtReceived" type="date" stack-label label="Data do Recebimento" />
          </div>
          <!-- QUANDO TIVER INSERINDO UM PEDIDO -->
          <div class="respRowForm q-gutter-y-sm q-mt-sm" v-show="!editRow">
            <q-list class="oneFields" bordered>
              <q-item-label header>Vinculos com Vendas em Aberto</q-item-label>
              <q-item tag="sellsWithoutOrders" v-ripple v-for="item in optSellsWithoutOrders" :key="item">
                <q-item-section side top>
                  <q-checkbox v-model="openSales" :val="item.recno" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>
                    {{ item.keyclient }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
          <!-- QUANDO TIVER EDITANDO UM PEDIDO -->
          <div class="respRowForm q-gutter-y-sm q-mt-sm column q-pa-md" style="border: 1px solid #c2c2c2; border-radius: 5px" v-show="editRow">
            <div class="title text-h6 text-grey q-mb-sm">
              Vendas Vinculadas
            </div>
            <div class="itens" v-for="venda in vendasVinculadas" :key="venda">
              <p>{{venda.vendas_vinculadas}}</p>
            </div>
          </div>
        </q-scroll-area>

        <div class="btns row justify-center q-pt-md">
          <q-btn outline no-caps color="negative" label="Cancelar" @click="onCancel" />
          <div class="q-mx-md" />
          <q-btn outline no-caps color="primary" label="Salvar" @click="onSave" />
          <div class="q-mx-md" />
          <q-btn v-show="editRow" no-caps color="negative" label="Excluir" @click="onDelete" />
        </div>
      </div>
    </q-dialog>

  </div>
</template>

<script>
import DateFormat from '../../helpers/DateFormat';
import DateEnglish from '../../helpers/DateEnglish';
import locale from 'quasar/lang/pt-BR';
import { mapActions } from 'vuex';
import { ref } from 'vue';
import { Dialog } from 'quasar';
import { defaultColor } from '../../helpers/defaultColor';
import { supabase } from '../../supabase';

export default {
  data() {
    return {
      enterprise: localStorage.getItem('enterprise'),
      modalOrders: false,
      filter: null,
      cols: [
        { name: 'orders',     align: 'center',    label: 'Pedido',              field: 'orders',     sortable: true },
        { name: 'qtd',        align: 'center',    label: 'Quantidade',      field: 'qtd',        sortable: true },
        { name: 'supplier',   align: 'center',    label: 'Fornecedor',          field: 'supplier',   sortable: true },
        { name: 'ordercost',  align: 'center',    label: 'Custo por Pedido',    field: 'ordercost',  sortable: true, format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) },
        { name: 'unitcost',   align: 'center',    label: 'Custo Médio (Unid)',  field: 'unitcost',   sortable: true, format: val => parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) },
        { name: 'tracking',   align: 'center',    label: 'Código de Rastreio',  field: 'tracking',   sortable: true },
        { name: 'dtorder',    align: 'center',    label: 'Data do Pedido',      field: 'dtorder',    sortable: true, format: val => DateFormat(val) },
        { name: 'dtreceived', align: 'center',    label: 'Data do Recebimento', field: 'dtreceived', sortable: true, format: val => DateFormat(val) },
      ],
      rows: [],
      resumoPedidos: [],
      visibleCols: ref([
        'orders', 'qtd', 'supplier', 'ordercost', 'unitcost', 'tracking', 'dtorder', 'dtreceived'
      ]),
      ptBR: locale.date,
      datePicker: null,
      dt_ini: '19000101',
      dt_fim: '30001231',

      //~> PARA O MODAL
      orders: null,
      statusFields: true,
      optSellsWithoutOrders: null,
      openSales: [],
      supplier: null,
      qtd: null,
      costttl: null,
      unitcost: null,
      dtOrder: null,
      dtReceived: null,
      tracking: null,
      oldTrack: null,
      rebuild: true,
      vendasVinculadas: null,

      //~> CONTROLA GRAVAÇÃO vs EDIÇÃO
      editRow: false,

      codeTracking: 'QH691307789BR',
    }
  },
  computed: {
    isMobile() {
      return (this.$q.screen.sm || this.$q.screen.xs);
    },
  },
  methods: {
    ...mapActions('Functions', [ 'rpc' ]),
    ...mapActions('Querys', [ 'insertData', 'updateData' ]),
    resetForm() {
      //~> Fazendo RESET caso o usuário cancele ou entra para preencher o Form
      this.openSales = []
      this.supplier = ''
      this.qtd = ''
      this.costttl = ''
      this.unitcost = ''
      this.dtOrder = ''
      this.tracking = ''
    },
    validFields() {
      if(
        this.supplier == '' || this.supplier == null ||
        this.qtd == '' || this.qtd == null ||
        this.costttl == '' || this.costttl == null ||
        this.unitcost == '' || this.unitcost == null
        // this.dtOrder == '' || this.dtOrder == null ||
        // this.tracking == '' || this.tracking == null
      ) {
        this.statusFields = false;
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Favor preencher todos os campos'
        });
      } else {
        this.statusFields = true;
      }
    },
    onAdd() {
      this.resetForm();
      this.modalOrders = true;
    },
    onCancel() {
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente sair? Ao sair o preenchimento será perdido.',
        persistent: true,
        cancel: true,
      }).onOk(() => {
        this.resetForm();
        this.editRow = false;
        this.modalOrders = false;
      })
    },
    async onSave() {

      this.validFields();
        if(!this.statusFields) return '';

      try {
        let dtOrder = this.dtOrder == null || this.dtOrder == '' ? 0 : String(this.dtOrder).replaceAll('-', '')   // DateEnglish(this.dtOrder)  // dateToString(this.dtOrder)
        let dtReceived = this.dtReceived == null || this.dtReceived == '' ? 0 : String(this.dtReceived).replaceAll('-', '')  // dateToString(this.dtReceived)
        let cost = parseFloat(this.costttl)
        let unit = parseFloat(this.costttl / this.qtd)

        if(this.editRow) {

          const recno = String(this.orders).replace('Pedido:', '')

          await this.updateData({
            table: 'GE_ORDERS',
            fields: {
              'GE_QUANTITY': this.qtd,
              'GE_SUPPLIER': this.supplier,
              'GE_ORDERCOST': String(this.costttl).replaceAll(',', '.'),
              'GE_UNITCOST': String(this.unitcost).replaceAll(',', '.'),
              'GE_TRACKINGCODE': this.tracking,
              'GE_DATEORDER': dtOrder,
              'GE_DATERECEIVED': dtReceived,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' ',
            },
            match: {
              'RECNO': recno
            }
          })

          let filter;
          if (String(this.tracking).indexOf('Pedido:') >= 0) {
            filter = 'Pedido:' + recno;
          } else {
            filter = this.oldTrack;
          }

          console.log(filter);

          await this.updateData({
            table: 'GE_SELLS',
            fields: { 'GE_TRACKINGCODE': this.tracking },
            match: { 'GE_TRACKINGCODE': filter }
          })

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Registro editado com sucesso!'
          });

          this.resetForm();
        } else {
          const { data } = await supabase
            .from('GE_ORDERS')
            .insert({
              'GE_QUANTITY': this.qtd,
              'GE_SUPPLIER': this.supplier,
              'GE_ORDERCOST': cost,
              'GE_UNITCOST': unit,
              'GE_TRACKINGCODE': this.tracking,
              'GE_DATEORDER': dtOrder,
              'GE_DATERECEIVED': dtReceived,
              'GE_ENTERPRISE': this.enterprise,
              'DELETED': ' ',
            })

          if( !this.tracking ) {
            this.tracking = 'Pedido:' + data[0].RECNO
          }
          
          this.openSales.forEach(async el => {
            await this.updateData({
              table: 'GE_SELLS',
              fields: { 'GE_TRACKINGCODE': this.tracking },
              match: { 'RECNO': el }
            })
          });

          Dialog.create({
            title: 'Gestão Eletrônicos',
            message: 'Pedido incluso com sucesso!'
          });
        }

        this.resetForm();
        this.modalOrders = false;
        this.editRow = false;
        this.executeQuery();
      } catch (error) {
        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: error.message
        });
      }
    },
    onDelete() {
      Dialog.create({
        title: 'Gestão Eletrônicos',
        message: 'Deseja realmente <span class="text-negative text-bold">EXCLUIR</span> o registro?',
        html: true,
        persistent: true,
        cancel: true,
      }).onOk(async () => {
        //NUNCA EXCLUIREMOS UM REGISTRO, APENAS MARCAMOS NO CAMPO DELETED COMO '*'

        const recno = String(this.orders).replace('Pedido:', '')
        await this.updateData({
          table: 'GE_ORDERS',
          fields: {
            'DELETED': '*',
          },
          match: {
            'RECNO': recno
          }
        })

        if(this.vendasVinculadas) {
          if(this.tracking == null || this.tracking == '' || this.tracking == this.orders) {
            // CASO A EXCLUSÃO SEJA ANTES DE TER UM CÓDIGO DE RASTREIO
            await this.updateData({
              table: 'GE_SELLS',
              fields: {
                'GE_TRACKINGCODE': '',
              },
              match: {
                'GE_TRACKINGCODE': this.orders
              }
            })
          } else {
            // CASO A EXCLUSÃO SEJA APÓS TER UM CÓDIGO DE RASTREIO
            await this.updateData({
              table: 'GE_SELLS',
              fields: {
                'GE_TRACKINGCODE': '',
              },
              match: {
                'GE_TRACKINGCODE': this.tracking
              }
            })
          }
        }


        Dialog.create({
          title: 'Gestão Eletrônicos',
          message: 'Registro excluído com sucesso!'
        });

        this.modalOrders = false;
        this.editRow = false;
        this.executeQuery();
      })
    },
    async executeQuery() {
      //~> PARA ALIMENTAR A TABELA
      let orders = await this.rpc({
        function: 'tOrders',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      this.rows = orders.map((order) => {
        return {
          orders: order.orders,
          qtd: order.qtd,
          supplier: order.supplier,
          ordercost: order.ordercost,
          unitcost: order.unitcost,
          tracking: order.tracking == null || order.tracking == '' ? '' : order.tracking,
          dtorder: order.dtorder == null || order.dtorder == '' ? '' : order.dtorder,
          dtreceived: order.dtreceived == null || order.dtreceived == '' ? '' : order.dtreceived,
        }
      });

      //~> PARA ALIMENTAR OS CARDS DO RESUMO
      let summary = await this.rpc({
        function: 'resumo_tOrders',
        enterprise: localStorage.getItem('enterprise'),
        date_start: this.dt_ini,
        date_end: this.dt_fim
      })

      this.resumoPedidos = summary.map((sum) => {
        return {
          apedir: sum.apedir == null || sum.apedir == '' ? 0 : sum.apedir,
          acaminho: sum.acaminho == null || sum.acaminho == '' ? 0 : sum.acaminho,
          entregues: sum.entregues == null || sum.entregues == '' ? 0 : sum.entregues,
          vlrttl: sum.vlrttl
        }
      });

      //~> PARA ALIMENTAR A RELAÇÃO DAS VENDAS QUE NÃO TEM PEDIDO ATRELADO AINDA
      let opt = await this.rpc({
        function: 'sells_without_orders',
        enterprise: localStorage.getItem('enterprise')
      })

      this.optSellsWithoutOrders = opt;
    },
    onDate() {
      this.dt_ini = String(this.datePicker.from).replaceAll('/','');
      this.dt_fim = String(this.datePicker.to).replaceAll('/','');

      this.executeQuery();
    },
    async onEditRow(_, row) {
      let recno_Order = 'Pedido:' + parseInt(row.orders);

      if(row.tracking == '' || row.tracking == null) {
        row.tracking = recno_Order;
      }

      this.oldTrack = row.tracking;
      console.log(this.oldTrack);

      const vendas = await this.rpc({
        function: 'vendas_vinculadas',
        enterprise: localStorage.getItem('enterprise'),
        league: row.tracking
      })

      this.vendasVinculadas = vendas;

      this.supplier = row.supplier
      this.qtd = row.qtd
      this.costttl = row.ordercost
      this.unitcost = row.unitcost
      this.dtOrder = DateEnglish(row.dtorder)  // DateFormat(row.dtorder)
      this.dtReceived = String(row.dtreceived).length < 8 ? '' : DateEnglish(row.dtreceived)  // DateFormat(row.dtreceived)
      this.tracking = row.tracking
      this.orders = recno_Order

      this.editRow = true;
      this.modalOrders = true;
    },
    onCost() {

      if(this.qtd < 0) { this.qtd = 0 }
      if(this.costttl < 0) { this.costttl = 0 }

      let val
      val = this.costttl / this.qtd
      val = parseFloat(val).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
      
      this.unitcost = val
    },
    exportTable(aTable) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(aTable[0]).join(";"),
        ...aTable.map(item => Object.values(item).join(";"))
        ].join("\n")
        .replace(/(^\[)|(\]$)/gm, "")
        .replaceAll("ç", "c")
        .replaceAll("ã", "a")
        .replaceAll("â", "a")
        .replaceAll("á", "a")
        .replaceAll("à", "a")
        .replaceAll("ê", "e")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("õ", "o")
        .replaceAll("ô", "o")
        .replaceAll("ú", "u");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "gestao_Pedido.csv");
      link.click();
    },
  },
  created() {
    defaultColor();
    this.executeQuery();
  }
}
</script>

<style lang="scss">
  @import url('../../globais/GlobalStyles.scss');
</style>